//import { Department } from './../../models/core';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, PLATFORM } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { AddressService } from 'services/address-service';
import { ContactService } from 'services/contact-service';
import { CustomerService } from 'services/customer-service';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { DepartmentService } from 'services/department-service';
import { ErrorService } from 'services/error-service';
import { SiteService } from 'services/site-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';

@autoinject
export class CustomerInfo {
    private customer: Models.Customer;
    private departments: Array<any>;
    private addresses: Array<Models.Address>;
    private contacts: Array<Models.Contact>;
    private sitesWithoutDepartment: Array<Models.Site>;

    private departmentNewFormVisible: boolean = false;
    private departmentEditFormVisible: number = null;
    private siteNewFormVisible: number = null;
    private siteEditFormVisible: number = null;
    private addressNewFormVisible: number = null;
    private addressEditFormVisible: number = null;
    private contactNewFormVisible: boolean = false;
    private contactEditFormVisible: number = null;

    constructor(
        private customerService: CustomerService,
        private departmentService: DepartmentService,
        private siteService: SiteService,
        private contactService: ContactService,
        private errorService: ErrorService,
        private router: Router,
        private toastService: ToastService,
        private eventAggregator: EventAggregator,
        private addressService: AddressService,
        private utility: Utility,
        private dialogService: DialogService,
        private prompt: Prompt
    ) { }

    private activate(params) {
        this.getCustomer(params.Id);
    }

    private getCustomer(Id) {        
        this.customerService
            .get(Id)
            .then((customer) => {                
                this.customer = customer;
                this.getDepartments();
                this.getAddresses();
                this.getContacts();
            })
            .catch((err) => this.errorService.handleError(err));
    }

    private attached(params) {
        this.eventAggregator.subscribe('departmentListReset', (value) => {
            return this.getDepartments();
        });
        this.eventAggregator.subscribe('siteListReset', (value) => {
            return this.getDepartments();
        });
        this.eventAggregator.subscribe('addressListReset', (value) => {
            return this.getAddresses();
        });
        this.eventAggregator.subscribe('contactListReset', (value) => {
            return this.getCustomer(this.customer.Id);
        });
        this.eventAggregator.subscribe('departmentFormNewClose', (value) => {
            this.departmentNewFormVisible = false;
        });
        this.eventAggregator.subscribe('departmentFormEditClose', (value) => {
            this.departmentEditFormVisible = null;
        });
        this.eventAggregator.subscribe('siteFormNewClose', (value) => {
            this.siteNewFormVisible = null;
        });
        this.eventAggregator.subscribe('siteFormEditClose', (value) => {
            this.siteEditFormVisible = null;
        });
        this.eventAggregator.subscribe('addressFormNewClose', (value) => {
            this.addressNewFormVisible = null;
        });
        this.eventAggregator.subscribe('addressFormEditClose', (value) => {
            this.addressEditFormVisible = null;
        });
        this.eventAggregator.subscribe('contactFormNewClose', (value) => {
            this.contactNewFormVisible = false;
        });
        this.eventAggregator.subscribe('contactFormEditClose', (value) => {
            this.contactEditFormVisible = null;
        });
    }

    private getContacts() {
        this.contactService.getAll('?$filter=CustomerId eq ' + this.customer.Id)
        .then((contacts) => {
            this.contacts = contacts;
        }).catch((err) => this.errorService.handleError(err));;
    }

    private getDepartments() {
        this.departmentService
            .getAll('?$filter=CustomerId eq ' + this.customer.Id)
            .then((departments) => {
            this.departments = departments;

            this.siteService
                .getByCustomer(this.customer.Id)
                .then((sites) => {
                    this.departments.forEach((department) => {
                        department.Sites = sites.filter((x) => x.DepartmentId === department.Id).sort((a,b) => a.Name > b.Name ? 1 : -1);
                    });
                    this.sitesWithoutDepartment = sites.filter((x) => !x.DepartmentId).sort((a,b) => a.Name > b.Name ? 1 : -1);
                })
                .catch((err) => this.errorService.handleError(err));
            })
            .catch((err) => this.errorService.handleError(err));
    }

    private getAddresses() {
        this.addressService
            .getAll('?$filter=CustomerId eq ' + this.customer.Id)
            .then((res) => {
            this.addresses = res;
            })
            .catch((err) => this.errorService.handleError(err));
    }

    private updateCustomer() {
      this.siteService
                .getByCustomer(this.customer.Id)
        this.customerService
            .put(this.customer, this.customer.Id)
            .then((res) => {
            this.eventAggregator.publish('customerListReset', 1);
            this.toastService.showSuccess('customer.updated');
            this.router.navigateToRoute('customer-list');
            })
            .catch((err) => this.errorService.handleError(err));
    }

    private editDepartment(id) {
        this.departmentEditFormVisible = id;
    }

    private editSite(site) {
      if(site.Hs == null || site.Vc == null){
        this.siteService
        .getAquacomLocation(site.Id)
        .then((res) =>{
            site.Hs = res.Hs;
            site.Vc = res.Vc;        
        })
        .catch((err) => this.errorService.handleError(err));
      }

      this.siteEditFormVisible = site.Id;
    }

    private editContact(id) {
        this.contactEditFormVisible = id;
    }

    private newSite(id) {
        this.siteNewFormVisible = id;
    }

    private gotoParentView() {
        this.eventAggregator.publish('customerListReset', 1);
        this.router.navigateToRoute('customer-list');
    }

    private canDeactivate() {
      if (
        this.departmentNewFormVisible === true ||
        this.addressNewFormVisible != null ||
        this.contactNewFormVisible === true ||
        this.siteNewFormVisible != null ||
        this.departmentEditFormVisible != null ||
        this.addressEditFormVisible != null ||
        this.contactEditFormVisible != null ||
        this.siteEditFormVisible != null
      ) {
        return this.dialogService.open({
          viewModel: Prompt,
          model: {
            header: 'dialog.subFormOpenHeading',
            message: 'dialog.subFormOpenMessage',
            actions: {
              delete: { enabled: false },
              save: { enabled: false },
              cancel: { enabled: true, t: 'dialog.cancel'  },
              dontsave: { enabled: false },
              continue: { enabled: true, t: 'dialog.continue'  }
            }
          }
        }).whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            return true;
          }
        });
      } else {
        return true;
      }
    }

}
