import { LanguageService } from './../../services/language-service';
import { DialogController, DialogService } from 'aurelia-dialog';
import { HttpClient } from 'aurelia-fetch-client';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { BindingSignaler } from 'aurelia-templating-resources';
import { InvoiceWasteByOptions } from 'models/InvoiceWasteBy';
import { BrregOrg } from 'models/brregOrg';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BrregService } from 'services/brreg-service';
import { CountryService } from 'services/country-service';
import { IndustryService } from 'services/industry-service';

@autoinject()
export class CustomerFormBasic {
  @bindable private customer: Models.Customer;
  private brregApiUrl: string = 'https://data.brreg.no/enhetsregisteret/enhet/';
  private httpClient = new HttpClient();
  private countries: Array<any>;
  private languages: Array<any>;
  private industries: Models.Industry[];

  protected invoiceWasteByOptions = InvoiceWasteByOptions.map((x) => {
    x.label = this.i18n.tr(x.label);
    return x;
  });

  constructor(
    private bindingSignaler: BindingSignaler,
    private countryService: CountryService,
    private languageService: LanguageService,
    private industryService: IndustryService,
    private i18n: I18N
  ) {}

  private async attached() {
    this.languages = this.languageService.getLanguages();
    this.countries = this.countryService.getCountryList();
    this.industries = await this.industryService.getAll();

    if (this.customer) {
      // most customers will be norwegian, so suggest that
      this.customer.Country = 'Norway';
    }

    if (!this.customer.IndustryId) {
      this.customer.IndustryId = 1;
    }
  }

  private fillByOrgNo() {
    this.httpClient
      .fetch(this.brregApiUrl + this.customer.OrgNo + '.json')
      .then((response) => response.json())
      .then((data: any) => {
        this.customer.Name = this.companyCase(data.navn);
        this.customer.Address1 = data.forretningsadresse.adresse;
        this.customer.Zip = data.forretningsadresse.postnummer;
        this.customer.City = data.forretningsadresse.poststed;
      });
  }

  private companyCase(value) {
    if (!value) {
      return;
    }
    value = value.toLowerCase();
    const words = value.split(' ');
    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      word = word[0].toUpperCase() + word.substr(1);
      if (word === 'As' || word === 'as') {
        word = 'AS';
      }
      words[i] = word;
    }
    return words.join(' ');
  }

  private setServiceInformationFileId(event: any) {
    this.customer.ServiceInformationFileId = event.detail.id;
  }

  private removeServiceInformationFileId(event: any) {
    this.customer.ServiceInformationFileId = null;
  }
}
