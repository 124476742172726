import { DeleteDialogService } from 'services/delete-dialog-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, containerless } from 'aurelia-framework';
import { bindable, bindingMode } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Models } from 'models/core';
import { ContactService } from 'services/contact-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@containerless
@autoinject
export class ContactFormInlineEdit {
  @bindable private contact: Models.Contact;

  private hasOrderedAquacomBefore: boolean = false;

  constructor(
    private contactService: ContactService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private validationController: ValidationController,
    private validator: Validator,
    private router: Router,
    private toastService: ToastService,
    private deleteDialogService: DeleteDialogService
  ) { }

  private attached() {
    ValidationRules
      .ensure('Name').required().withMessage(this.i18n.tr('general.requiredField'))
      .ensure('Email').required().withMessage(this.i18n.tr('general.requiredField'))
      .ensure('Email').email().withMessage(this.i18n.tr('general.incorrectEmailFormat'))
      .on(this.contact);

      if (this.contact && this.contact.OrderAquacomUser) {
        this.hasOrderedAquacomBefore = true;
      }
  }

  private updateContact() {
    this.contactService
      .put(this.contact, this.contact.Id)
      .then((res) => {
        this.eventAggregator.publish('contactListReset', 1);
        this.eventAggregator.publish('contactFormEditClose', 1);
        this.toastService.showSuccess('contact.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteContact() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.contactService
          .delete(this.contact.Id)
          .then((res) => {
            this.eventAggregator.publish('contactFormEditClose', 1);
            this.eventAggregator.publish('contactListReset', 1);
            this.toastService.showSuccess('contact.deleted');
          })
          .catch((err) => this.errorService.handleError(err));
      }
    );   
  }

  private validateBeforeSave() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.contact)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {
          this.updateContact();
        }
      });
  }

  private cancelEdit() {
    this.eventAggregator.publish('contactFormEditClose', 1);
    this.eventAggregator.publish('contactListReset', 1);
  }
}
